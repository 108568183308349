import { useEffect, useState } from 'react';

import { apiService } from '../../services/api-service';
import { type User, type Venue } from '../../types';
import { isOrgMember } from '../../types/user';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';
import { OrganizationDetailsSessions } from '../Organization/Details/Sessions';
import { SessionTrackHistory } from '../Session';
import { OrganizationPanel } from './Org';

function HostSessions(): JSX.Element | null {
  const [myVenue, setMyVenue] = useState<Venue | null>();

  useEffect(() => {
    const init = async () => {
      const resp = await apiService.venue.getMyVenue();
      if (!resp) return;

      setMyVenue(resp.data.venue);
    };

    init();
  }, []);

  if (!myVenue?.id) return null;

  return (
    <div className='w-full h-full text-white'>
      <div className='w-full flex justify-between items-start'>
        <div className='flex items-center'>
          <ScoreboardIcon className='w-6.5 h-6.5 fill-current' />
          <div className='ml-2 font-medium text-2xl'>Analytics</div>
        </div>
      </div>
      <main className='w-full mt-10 scrollbar'>
        <SessionTrackHistory searchParams={{ venueId: myVenue.id }} />
      </main>
    </div>
  );
}

export function Sessions(props: { user: User }): JSX.Element {
  if (isOrgMember(props.user)) {
    return (
      <OrganizationPanel user={props.user}>
        <OrganizationDetailsSessions header />
      </OrganizationPanel>
    );
  }
  return <HostSessions />;
}
